import * as React from "react"
import HeaderComponent from "../../Components/HeaderComponent";
import Layout from '../../Components/Layout';
import Seo from "../../Components/Seo";
import Legal from '../../modules/Legal'

const index = () => {
    return (
        <>
            <Seo title="Legal | Webbermill"/>
            <Layout className="Legal">
                <title>Legal Page</title>
                <HeaderComponent hasProduct/>
                <Legal />
            </Layout>
        </>
    );
};

export default index;