import React from 'react'

const TermOfService = () => {
  return (
    <section className="term">
        <p>By signing unto webbermill.com, you agree to be bound by the following terms and conditions as shall be updated from time to time. <br /> These terms and conditions apply to all visitors, users and others who access this website.</p>
        <p></p>
        <h5>GENERAL TERMS</h5>
        <ul>
            <li>Users of Webbermill.com shall provide necessary true and current information for the purpose of creating a User account, which includes email address, password, name mobile number and an image.</li>
            <li>The Administrator prevents infringement of copyright and intellectual property right infringement in the course of using webbermill.com and shall delete any User material, which infringes intellectual property rights, at its own discretion without prior notice. The Administrator may terminate Users’ access to webbermill.com in the event that such Users repeatedly infringe rights or commit any actions in contrast to these Terms and Conditions.</li>
            <li>The fees charged for using our Services are listed on Webbermill.com.</li>
            
    
        </ul>
        <h5>USAGE OF WEBBERMILL.COM</h5>
        <p>All Users shall:</p>
        <ul>
            <li>Provide true, complete and current data during registration and keep such data updated.</li>
            <li>Immediately change data for accessing Webbermill.com, when there is a suspicion that the email address and password used for logging into Webbermill.com were disclosed or probably used by the third parties.</li>
            <li>Notify the Administrator of unauthorized access to the personal account and/or of unauthorized access to and/or use of Users’ login and password;</li>
            <li>Prevent other Users’ getting access to the personal account or any specific information contained on it, if this can lead to violation of the laws of Ghana and/or these Terms and Conditions;</li>
            <li>Avoid posting of information and objects (including references hereto) to Webbermill.com, which may infringe other persons’ rights and interests;</li>
            <li>Avoid posting of information and objects (including references hereto) to Webbermill.com prohibited by these Terms and Conditions and by applicable law.</li>
            <li>Not confuse other Users concerning his/her personality using login and password of any other registered User.</li>
            <li>Not illegally download, store, post, distribute or provide access to or in any other way use intellectual property of other Users and third parties.</li>
            <li>Not perform bulk mailing to the addresses of other Webbermill.com Users without their consents;</li>
            <li>Not use software and pursue any other actions aimed to interference with normal operation of Webbermill.com or other Users.</li>
        </ul>
       
        {/* <h5>POSTING OF PROPERTIES BY THE USER</h5>
        <ul>
            <li>The Administrator may request User to provide documentations confirming status of properties posted on Webbermill.com.</li>
            <li>The User, who posts properties with the aim of leasing such properties through Webbermill.com shall place information about them in accordance with these Terms and Conditions and provide precise and complete information about the properties, as well as about the terms and conditions of leasing them out. When the User places information about the properties posted on Webbermill.com, he/she hereby confirms being legally authorized to rent out the properties pursuant to the laws of Ghana, as well as that he/she has obtained all necessary approvals.</li>
            <li>The User shall thoroughly check all information posted concerning properties listed on Webbermill.com and, in case any incorrect information is detected, such inaccurate information shall immediately be corrected. If no possibility exists to do so, the User shall withdraw the property from Webbermill.com.</li>
            <li>The cost of rent shall be exact. If it is perceived to be changed due to any specific circumstances, the terms and conditions of change of cost of rent shall be clearly stated in the post on Webbermill.com.</li>
        </ul> */}
        <h5>EACH USER REPRESENTS AND WARRANTS THAT:</h5>
        <ul>
            <li>He/she undertakes full responsibility for obtaining of all necessary permits in relation to any User content, which he/she represents or displays.</li>
            <li>Any User content represented or displayed by him/her does not infringe any copyrights, patents, rights for trademarks, firm names, commercial secrets or any other personal or proprietary rights of any third party <strong>("Third party rights")</strong>.</li>
        </ul>
         <h5>THE USER IS NOT ALLOWED TO POST OR SHARE:</h5>
         <ul>
             <li>False, misleading or deceitful information;</li>
             <li>Disreputable, defamatory, threatening or harassing, improper, unacceptable information;</li>
             <li>Discriminative information, or information that facilitates discrimination on the basis of race, Sex, religion, nationality, invalidity, sexual orientation or age;</li>
             <li>Information which violates these Rules;</li>
             <li>Information which violates applicable laws and regulations (including, without limitation, those regulating export control, consumer protection, unfair competition or false advertising, intellectual property rights);</li>
             <li>Direct or indirect references to any other web sites, which comprise any content being able to violate these Rules;</li>
             <li>Data infringing personal (non-proprietary) rights and intellectual property rights of the third parties;</li>
             <li>Information that facilitates fraud, deception or breach of faith;</li>
             <li>Information violating or encroaching on the third party’s property, commercial secret or right to privacy;</li>
             <li>Personal or identifying information about other persons without their express consent;</li>
             <li>Information comprising data which may encroach on privacy right, abuse anybody’s honour, merit or business reputation;</li>
             <li>False or misleading information;</li>
             <li>Viruses or any other techniques being able to damage the Resource, Administrator or other Users;</li>
             <li>References or information about websites competing with Webbermill.com.</li>
         </ul>
         <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
         <ul>
             <li>The User grants to the Administrator and other users a non-exclusive right over posts for use solely in the scope of functionality provided by Webbermill.com, except when such use damages or may damage legally protected right holder’s interests.</li>
             <li>The User also grants to the Administrator a non-exclusive right to use content, which is located on Webbermill.com and legally owned by him/her, without compensation so that the Administrator would be able to ensure operation of Webbermill.com to the extent determined by its functionality. The abovementioned non-exclusive right is provided for the period of posting of content to Webbermill.com all over the world. The Administrator is entitled to assign rights described in this clause to the third parties.</li>
             <li>Any use of Webbermill.com or any content therein, except that permitted by these Terms and Conditions or in the event of express right holder’s consent to such use, without prior written consent of a right holder is strictly prohibited.</li>
             <li>Responsibility for violation of exclusive rights.</li>
             <li>The User shall be solely responsible for any content or other information, which he/she downloads or in any other way make publicly available (posts) on Webbermill.com. The User shall not download, distribute or post content Webbermill.com, if he/she is not entitled to such activity. In case of infringement of rights, the rules of filing of notification on the infringement of rights stated herein shall be used.</li>
             <li>The Administrator may, review Webbermill.com for the presence of any prohibited content and may delete or displace (without notice) any content at its discretion, for any reason or without it, including but not limited to deletion or displacement of content which violates these Terms and Conditions, laws and/or may infringe rights, cause damages or endanger safety of other Users or third parties.</li>
             <li>Materials on Webbermill.com, except those posted by the User, including but not limited to texts, software, scripts, graphics, photos, sounds, music, videos, interactive functions, etc. <srtong>("Materials")</srtong> and trademarks, service marks and logos included in it <strong>("Marks")</strong> belong to the Administrator representing items of copyright and of any other intellectual property rights. Unauthorized use of such Materials and Marks without prior notice of the Administrator is prohibited.</li>
             
         </ul>
         <h5>NOTICE FOR CLAIMS OF INTELLECTUAL PROPERTY VIOLATIONS AND COPYRIGHT INFRINGEMENT</h5>
         <ul>
             <li>If you are a holder of intellectual property rights or a person authorized to act in the name of a holder of intellectual property rights and you reasonably believe that information which is posted on Webbermill.com infringes your intellectual property rights or intellectual property rights of a person, in whose name you act, you may provide notification to the Administrator requiring for detection of such material. In this regard you shall warrant that your appeal has a legal basis, and you act in good faith according to law.</li>
             <li>To provide relevant notification concerning infringement of rights, you shall ensure that your request corresponds to the form below and includes the following:</li>
             <li>An appeal should include physical or electronic signature of a person empowered for acting in the name of a holder of exclusive right, which is believed to be infringed.</li>
             <li>The items of intellectual property right, rights which are allegedly infringed, shall be specified. If several items exist, the entire list of such items shall be provided;</li>
             <li>You shall specify materials (with an indication of specific URL-pages), which are stated to infringe rights or themselves are the objects of infringement;</li>
             <li>You shall provide contact information for the Administrator to contact you, for example, address, phone number, and email address;</li>
             <li>Signed application reasonably believed to be objects of complaint concerning infringement of intellectual property rights used without a right holder’s or its representative’s consent, and also not allowed by law;</li>
             <li>Signed application with regard to that a holder of intellectual property rights releases the Administrator from any third parties' claims related to deletion of relevant materials by the Administrator;</li>
             <li>Signed application with regard to that information contained in a notification is accurate under penalty of perjury, and you are authorized to act in the name of a holder of exclusive right, which has been supposedly infringed;</li>
             <li>Statutory regulations indicated which you believe to be violated in connection to using of disputable content;</li>
             <li>State jurisdiction in which you believe the rights to be infringed;</li>
             <li>Copies of documents establishing rights for an object of intellectual property right, which is subject to security, as well as a document that confirms powers for acting in the holder’s name, in attachments to your appeal.</li>
         </ul>
         <h5>ENFORCEMENT</h5>
         <ul>
             <li>The Administrator reserves a right to delete or block access to information posted by User without notice in the event of:</li>
             <li>Receiving of Judgments from competent public authorities</li>
             <li>Claim of a holder of intellectual property rights to terminate infringement of his/her rights by a user on the Webbermill.com, other infringements of rights or legal interests of other Webbermill.com Users, of legal entities or individuals upon their reasonable appeal;</li>
             <li>Detecting information posted to Webbermill.com which is prohibited under these Rules.</li>
             <li>The Administrator shall be entitled to block access to information posted by users to the Webbermill.com at its sole discretion having provided a user with relevant substantiation.</li>
         </ul>
          <h5>USERS AND ORGANIZATIONS INTERACTION</h5>
          <ul>
              <li>The Administrator disclaims any responsibility for User’s interaction with any organizations and/or persons in the course of using Webbermill.com. This includes, but not limited to, payments as well as any other interaction in relation to other organizations and/or individuals. Transactions are concluded only between Users and such organizations and/or individuals. The Administrator disclaims responsibility for such interactions or other losses incurred following such relations or interactions. If any dispute arises between you and one or several other users, you shall indemnify the Administrator, its officials, employees, agents and successors from any and all claims, requirements and losses (whether direct or indirect) of whatsoever kind or nature, which arise or relate to such disputes and /or goods and services.</li>
          </ul>
           <h5>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h5>
           <ul>
               <li>WEBBERMILL.COM AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH WEBBERMILL.COM ARE PROVIDED BY WEBBERMILL.COM ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. WEBBERMILL.COM MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF WEBBERMILL.COM, OR INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH WEBBERMILL.COM, UNLESS OTHERWISE SPECIFIED IN WRITING. </li>
               <li>YOU EXPRESSLY AGREE THAT YOUR USE OF WEBBERMILL.COM IS AT YOUR SOLE RISK.</li>
               <li>TO THE FULL EXTENT PERMISSIBLE BY LAW, WEBBERMILL.COM DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF SUITABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WEBBERMILL.COM DOES NOT WARRANT THAT SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH WEBBERMILL.COM, SERVERS OR ELECTRONIC COMMUNICATIONS SENT WEBBERMILL.COM ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, WEBBERMILL.COM WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH WEBBERMILL.COM, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.</li>

           </ul>
           <h5>INDEMNIFICATION</h5>
           <ul>
             <li>Users agree to indemnify Webbermill.com as well as its officers, directors, employees, agents, from and against all losses, expenses, damages and costs, including attorney's fees, resulting from any violation of this Terms and Conditions (including negligent or wrongful conduct).</li>
           </ul>
            <h5>MODIFICATIONS</h5>
           <ul>
             <li>Webbermill.com reserves the right to modify these Terms and Conditions. Such modifications shall be effective immediately upon posting on Webbermill.com. You are responsible for the reviewing of such modifications. Your continued access or use of Webbermill.com shall be deemed your acceptance of the modified terms and conditions.</li>
           </ul>
            <h5>GOVERNING LAW</h5>
           <ul>
             <li>Webbermill.com is operated under the laws and regulations of Ghana. Users agree that Ghanaian courts will have jurisdiction over any dispute or claim relating to the use of Webbermill.com.</li>
           </ul>
    </section>
  )
}

export default TermOfService