import React from 'react'

const AntiSpamPolicy = () => {
  return (
    <section className="term">
        <p>Webbermill.com has a no-tolerance spam policy. “Spam” means unsolicited communication to persons with whom our customers do not have a business relationship or who have not specifically requested (opted-in to) our customers’ mailings.</p>
        <p>We monitor the Services for any large increases in sending emails. A user’s account will be terminated if the user sends unsolicited email messages. Please report any suspected abuse to info@webbermill.com ISPs, and Blacklist administrators may contact us at info@webbermill.com. </p>
        <p>Please forward the complete email in question, including headers. If applicable, please also unsubscribe from the newsletter using the link at the bottom of the email if you no longer wish to receive emails from the sender. We will take the appropriate action against the sender of the email in question.</p>
        <p>To use our Services, you must agree to send only permission-based emails. This means all recipients must have opted-in to receive communications from the sending entity.</p>
        <p>An opt-in can occur either via a sign-up form on a website, at a point-of-sale sign-up form, or on a physical sign-up sheet. Purchased lists may not be used with the Services, regardless of the source or permission status.</p>
        <p>You may not use webbermill.com’s services to send messages to recipients that were:</p>
        <ul>
            <li>Harvested or scraped from websites</li>
            <li>Acquired through purchased lists (whether they are opt-in or not)</li>
        </ul>
        <p>Additionally, the following sending practices are prohibited for all emails sent from webbermill.com:</p>
        <ul>
            <li>Subscription forms that subscribe users to an unrelated list</li>
            <li>Sending unrelated offers or unrelated content to your recipient list outside of the brand they originally opted to receive messages from.</li>
        </ul>
        <p>You may:</p>
        <ul>
            <li>Send out a regular broadcast and triggered emails to recipients who have opted-in to receive them</li>
            <li>Send out information and content to recipients who have requested to receive content on that topic from your organization</li>
            <li>Send out user-triggered emails that result from actions carried out on your website</li>
        </ul>
        <h5>Procedure for Handling Complaints</h5>
        <p>Your account will be immediately terminated if we determine in our sole discretion that you send spam.</p>
        <p>To determine whether you have sent spam we will:</p>
        <ul>
            <li>review the content of the message in question</li>
            <li>review your subscriber list for patterns common to harvested lists</li>
            <li>review the spam complaint</li>
            <li>view the records to see when the subscriber was subscribed and their IP address</li>
        </ul>
        <h5>Prohibited Content</h5>
        <p>Webbermill.com prohibits the use of the Services to send content that:</p>
        <ul>
            <li>Provides, sells, or offers to sell (or services related to): pornography; escort services; illegal goods; illegal drugs; illegal drug contraband; pirated computer programs; instructions on how to assemble or otherwise make bombs, grenades, or other weapons</li>
            <li>Provides sales or offers to sell or rent any mailing list</li>
            <li>Displays material that exploits children, or otherwise exploits children under 18 years of age</li>
            <li>Posts or discloses any personally identifying information or private information about children</li>
            <li>Provides material that is abusive, bigoted, prejudiced, racist, hateful, profane, obscene, violent, harassing, fraudulent, deceptive, misleading, or otherwise illegal content</li>
            <li>Violates the copyright, trademark, patent, trade secret, intellectual property, or other rights of another</li>
            <li>Sells or promotes any products or services that are unlawful in the location at which the content is posted or received</li>
            <li>Introduces viruses, worms, harmful code, and/or Trojan horses on the Internet</li>
            <li>Promotes solicits or participates in pyramid schemes</li>
            <li>Engages in any libelous, defamatory, scandalous, threatening, harassing activity</li>
            <li>Posts any content that advocates, promotes, or otherwise encourages violence against any governments, organizations, groups, or individuals or which provides instruction, information, or assistance in causing or carrying out such violence.</li>
        </ul>
        <p>Webbermill.com reserves the right to prohibit the use of the Services by any entity at its sole discretion.</p>
    </section>
  )
}

export default AntiSpamPolicy