import * as React from 'react';
import legalcontent from '../../../content/legalPageContent.json'
import { FooterComponent } from '../../Components/FooterComponent';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TermOfService from './components/TermOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import AntiSpamPolicy from './components/AntiSpamPolicy';

const index = () => {
    return (
        <>
            <section className='legal'>
                <div className='legal__content container'>


                    <div className='legal__content-tabs mt-3'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-3 col-lg-3'></div>
                            <div className='col-sm-12 col-md-9 col-lg-9'>
                              
                            </div>
                        </div>
                        <Tabs>
                            <div className='row mt-3'>
                                <div className='col-sm-12 col-md-3 col-lg-3 '>
                                    <TabList>
                                        {
                                            legalcontent.tabList.map((list, index) => (
                                                <Tab key={index}>{list}</Tab>
                                            ))
                                        }
                                    </TabList>
                                </div>
                                <div className='col-sm-12 col-md-9 col-lg-9'>
                                           <TabPanel key="1">
                                             <div className='legal__content-text'>
                                                <h5>{legalcontent.title}</h5>
                                            </div>
                                             <div>
                                                 <TermOfService />
                                             </div>
                                            </TabPanel>
                                             <TabPanel key="2">
                                                <div className='legal__content-text'>
                                                    <h5>PRIVACY POLICY</h5>
                                                </div>
                                                <div>
                                                   <PrivacyPolicy />
                                                </div>
                                            </TabPanel>
                                             <TabPanel key="3">
                                             <div className='legal__content-text'>
                                                    <h5>ANTI-SPAM POLICY</h5>
                                                </div>
                                                <div>
                                                  <AntiSpamPolicy /> 
                                                </div>
                                            </TabPanel>
                                             <TabPanel key="4">
                                               <div className='legal__content-text'>
                                                    <h5>COOKIE POLICY</h5>
                                                </div>
                                                <div>
                                                  <h5>Not Available</h5>
                                                </div>
                                            </TabPanel>
                                             <TabPanel key="5">
                                               <div className='legal__content-text'>
                                                    <h5>ABUSE POLICY</h5>
                                                </div>
                                                <div>
                                                  <h5>Not Available</h5>
                                                </div>
                                            </TabPanel>
                                            
                                    {/* {
                                        legalcontent.tabContent.map((content, index) => (
                                            <TabPanel key={index}>
                                                {content}
                                            </TabPanel>
                                        ))
                                    } */}
                                </div>
                            </div>


                        </Tabs>
                    </div>
                </div>
            </section>
            <FooterComponent />
        </>
    );
};

export default index;
